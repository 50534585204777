import React, { Component } from 'react'

import Landing from './pages/containers/Landing';

export class App extends Component {
  render() {
    return (
      <Landing/>
    )
  }
}

export default App
