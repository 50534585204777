import React from 'react'

export default function SkyLayout(props) {
  return (
      <div className="firstPlane">
            
              {props.children}
            
        </div>
  )
}
